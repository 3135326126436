// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bible-js": () => import("./../../../src/pages/bible.js" /* webpackChunkName: "component---src-pages-bible-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chords-js": () => import("./../../../src/pages/chords.js" /* webpackChunkName: "component---src-pages-chords-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-lyrics-js": () => import("./../../../src/pages/lyrics.js" /* webpackChunkName: "component---src-pages-lyrics-js" */),
  "component---src-templates-chord-page-amp-js": () => import("./../../../src/templates/chordPage.amp.js" /* webpackChunkName: "component---src-templates-chord-page-amp-js" */),
  "component---src-templates-chord-page-js": () => import("./../../../src/templates/chordPage.js" /* webpackChunkName: "component---src-templates-chord-page-js" */),
  "component---src-templates-lyric-page-js": () => import("./../../../src/templates/lyricPage.js" /* webpackChunkName: "component---src-templates-lyric-page-js" */)
}

